//
// user.js
// Use this to write your custom JS
//
let $validateForm = $("#validateform");
let $contactForm = $("#contactform");
let $message = $("#message");
let $contactName = $("#contactName");
let $contactEmail = $("#contactEmail");
let $contactChat = $("#contactChat");
let $contactSubject = $("#contactSubject");
let $contactMessage = $("#contactMessage");
let $submit = $("#submit");

$validateForm.on("submit", function (e) {
  e.preventDefault();
  let action = $(this).attr("action");
  $message.slideUp(750, function () {
    $message.hide();
    $contactName.prop("disabled", true);
    $contactEmail.prop("disabled", true);
    $contactChat.prop("disabled", true);
    $submit.prop("disabled", true);
    $.post(
      action,
      {
        name: $contactName.val(),
        email: $contactEmail.val(),
        chat: $contactChat.is(":checked") ? 1 : 0,
      },
      function (data) {
        $message.html(data);
        $message.slideDown("slow");
        $validateForm.find("img.loader").fadeOut("slow", function () {
          $(this).remove();
        });
        $contactName.prop("disabled", false);
        $contactEmail.prop("disabled", false);
        $contactChat.prop("disabled", false);
        $submit.prop("disabled", false);
        if (data.match("Successfully") != null) {
          $contactName.val("");
          $contactEmail.val("");
          $contactChat.prop("checked", false);
          $("#form-header").slideUp("slow");
        }
      }
    );
  });
  return false;
});


$contactForm.on("submit", function (e) {
  e.preventDefault();
  let action = $(this).attr("action");
  $message.slideUp(750, function () {
    $message.hide();
    $contactName.prop("disabled", true);
    $contactEmail.prop("disabled", true);
    $contactSubject.prop("disabled", true);
    $contactMessage.prop("disabled", true);
    $submit.prop("disabled", true);
    $.post(
      action,
      {
        name: $contactName.val(),
        email: $contactEmail.val(),
        subject: $contactSubject.val(),
        message: $contactMessage.val(),
      },
      function (data) {
        $message.html(data);
        $message.slideDown("slow");
        $validateForm.find("img.loader").fadeOut("slow", function () {
          $(this).remove();
        });
        $contactName.prop("disabled", false);
        $contactEmail.prop("disabled", false);
        $contactSubject.prop("disabled", false);
        $contactMessage.prop("disabled", false);
        $submit.prop("disabled", false);
        if (data.match("Successfully") != null) {
          $contactName.val("");
          $contactEmail.val("");
          $contactSubject.val("");
          $contactMessage.val("");
          $("#form-header").slideUp("slow");
        }
      }
    );
  });
  return false;
});
